export const status = "Live";
export const serverLink =
    status === "Dev"
        ?
        //"http://localhost:4480/"
        "http://192.168.1.75:4480/"
        : "https://api.bazeuniversity.edu.ng:5001/";

export const paymentAPIKey = status === "Dev" ? "pk_test_4afdc4ff65ce2328e5dd6a678f78103e0fe32c42" :  "pk_live_12317e78152cbe1f4fa00ce79b99f497c8e2b989" ;

export const generate_token = (length) =>{
    let a = "1234567890".split("");
    let b = [];
    for (let i=0; i<length; i++) {
        let j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}


export const formatDateAndTime = (date, option) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day =
            user_date.getDate() < 10
                ? "0" + user_date.getDate()
                : user_date.getDate();
        const hour =
            user_date.getHours() < 10
                ? "0" + user_date.getHours()
                : user_date.getHours();
        const min =
            user_date.getMinutes() < 10
                ? "0" + user_date.getMinutes()
                : user_date.getMinutes();
        const sec =
            user_date.getSeconds() < 10
                ? "0" + user_date.getSeconds()
                : user_date.getSeconds();

        let date_string = "";
        if (option === "date_and_time")
            date_string = `${day}-${
                monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
        else if (option === "date")
            date_string = `${day}-${
                monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()}`;
        else if (option === "day") date_string = day;
        else if (option === "full_month")
            date_string = monthNames[user_date.getMonth()];
        else if (option === "short_month")
            date_string = monthNamesShort[user_date.getMonth()];
        else if (option === "year_only") date_string = user_date.getFullYear();

        return date_string;
    } else {
        return "--";
    }
};