import './App.css';
import logo from './img/logo_.jpg'
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import {formatDateAndTime, generate_token, paymentAPIKey, serverLink} from "./resources/url";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {usePaystackPayment} from "react-paystack";
import { Document, Page, pdfjs } from 'react-pdf';

function useQuery() {
 let currentLocation = window.location.search
      return new URLSearchParams(currentLocation);
}
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function App() {
  let query = useQuery();
  let queryData = query.get("cert");

  const [step, setStep] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [certData, setCertData] = useState({
    EntryID: "",
    StudentID: "",
    FirstName: "",
    MiddleName: "",
    Surname: "",
    Degree: "",
    Honours: "",
    ClassOfDegree: "",
    CertificateNumber: "",
    AwardDate: "",
    IsGenerated: "",
    Batch: "",
    CreatedBy: "",
    CreatedDate: "",
    });
  const [formData, setFormData] = useState({
    CertNo: "",
    EmailAddress: "itri@bazeuniversity.edu.ng",
    Amount: 5000,
    flexCheckDefault: "0",
  })
  const config = {
    reference: "TRANX"+generate_token(10),
    email: formData.EmailAddress,
    amount: formData.Amount * 100,
    publicKey: paymentAPIKey,
  };

  useEffect(() => {
    if (queryData !== null) {
      setFormData({
        ...formData,
        CertNo: queryData
      })
    }
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getInfo = async (cert_no, reference) => {
    try {
      let sendData = {
        ...formData,
        UserID: "",
        CertNo: cert_no,
        TransactionReference: reference.trxref,
        PaymentMethod: "Paystack",
        TransactionType: "Certificate Verification",
      };
      await axios.post(`${serverLink}cert-verifier/cert-details`, sendData) .then((res) => {
          if (res.data.message === "success"){
            let data = res.data.result[0];
            setCertData({
              ...certData,
              EntryID: data.EntryID,
              StudentID: data.StudentID,
              FirstName: data.FirstName,
              MiddleName: data.MiddleName,
              Surname: data.Surname,
              Degree: data.Degree,
              Honours: data.Honours,
              ClassOfDegree: data.ClassOfDegree,
              CertificateNumber: data.CertificateNumber,
              AwardDate: data.AwardDate,
              IsGenerated: data.IsGenerated,
              Batch: data.Batch,
              CreatedBy: data.CreatedBy,
              CreatedDate: data.CreatedDate,
            })
            setFormData({ ...formData, flexCheckDefault: "0", })
            setStep(2)
            document.getElementById("viewer").scrollIntoView();
          }else if (res.data.message === "invalid"){
            setStep(3)
            document.getElementById("viewer").scrollIntoView();
          }else{
            toast.error("Something went wrong completing the transaction. Please send the payment receipt to servicedesk@bazeuniversity.edu.ng");
          }

        })
    } catch (e) {
      toast.error("NETWORK ERROR");
    }
  }

  const onSuccess = (reference) => {
    getInfo(formData.CertNo, reference)
  };

  const onClose = () => {
    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);

  const paymentInitialize = () => {
    if (formData.CertNo.toString().trim() === "") {
      toast.error("Please enter certificate number");
      return false;
    }
    initializePayment(onSuccess, onClose)
  }

  const onEdit = (e) => {
    let value = e.target.value;
    if (e.target.id === "flexCheckDefault") {
      if (e.target.checked){
        value = "1"
      }else{
        value = "0"
      }
    }
    setFormData({
      ...formData,
      [e.target.id]: value
    })
  }

  return (
    <div className="App">
      <div>
        <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
          {/* <div style={{backgroundColor:"#001D23"}} className="top-bar text-white row gx-0 align-items-center d-none d-lg-flex">*/}
          {/*  <div className="col-lg-8 px-5 text-start">*/}
          {/*    <small><i className="fa fa-map-marker-alt me-2" />2C44+7CR, Plot 686, Jabi Airport Road Bypass, Cadastral Zone, Abuja</small>*/}
          {/*    <small className="ms-4"><i className="fa fa-envelope me-2" />servicedesk@bazeuniversity.edu.ng</small>*/}
          {/*  </div>*/}
          {/*  <div className="col-lg-4 px-5 text-end">*/}
          {/*    <small>Follow us:</small>*/}
          {/*    <a className="text-white-50 ms-3" target={"_blank"} href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a>*/}
          {/*    <a className="text-white-50 ms-3" target={"_blank"} href="https://twitter.com/login"><i className="fab fa-twitter" /></a>*/}
          {/*    <a className="text-white-50 ms-3" target={"_blank"} href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" /></a>*/}
          {/*    <a className="text-white-50 ms-3" target={"_blank"} href="https://www.instagram.com/"><i className="fab fa-instagram" /></a>*/}
          {/*  </div>*/}
          {/*</div> */}
          <nav style={{ backgroundColor: "#001D23" }} className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <a href="/" className="navbar-brand ms-4 ms-lg-0">
              <h1 className="fw-bold text-primary m-0"><img src={logo} width="60px" style={{ borderRadius: '5px' }} />  &nbsp;<span className="text-white" style={{fontSize: '14px'}}>Baze University</span></h1>
            </a>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-4 p-lg-0">
                {/*<a href="#" className="nav-item nav-link active">Home</a>*/}
              </div>
              <div className="d-none d-lg-flex ms-2">
                <span className="btn btn-outline-primary py-2 px-3" onClick={() => {
                  document.getElementById("footer").scrollIntoView();
                }} >
                  Contact Us
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </span>
              </div>
            </div>
          </nav>
        </div>
        {/* Navbar End */}
        {/* Carousel Start */}
        <div className="container-fluid p-0 mb-5">
          <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="w-100" src="https://guardian.ng/wp-content/uploads/2017/08/BAZE-University-1062x598.jpg" alt="Image" />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">
                        <h1 className="display-4 text-white mb-3 animated slideInDown screenSize" style={{fontSize: '36px'}}>Certificate Verification System</h1>
                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                          Enter certificate number of scan QR Code on certificate</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: '800px', borderRadius: '10px' }}>
                          {/*{*/}
                                <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s" style={{marginBottom: '50px'}}>
                                  <div className="h-100 bg-white p-4" style={{ borderRadius: '5px'}}>
                                    <form>
                                      <div className="row g-3">

                                        <div className="col-12">
                                          <div className="form-floating" style={{color: "grey"}}>
                                            <input type="text" id="CertNo" className="form-control bg-light "  value={formData.CertNo} onChange={onEdit}  placeholder="Certificate Number"/>
                                            <label htmlFor="email">Certificate Number</label>
                                          </div>
                                        </div>
                                        <div className="alert alert-info text-center">
                                          You will be charged NGN5,000 for this service
                                        </div>
                                        <div className="form-check" style={{textAlign: 'left', color: 'grey'}}>
                                          <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={onEdit}  />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                              I hereby confirm that the certificate number provided is correct.
                                            </label>
                                        </div>

                                        <div className="col-12">
                                          <button type="button" id="pay" className={`btn btn-primary px-5 w-100 ${formData.flexCheckDefault.toString() === "0" ? "disabled" : "" }`} style={{height: '60px'}} onClick={() => { paymentInitialize(); }} >
                                            Pay Now
                                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                              <i className="fa fa-arrow-right"/>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                          {/*      :*/}
                          {/*      <>  <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" id="CertNo" onChange={onEdit} placeholder="certificate number" />*/}
                          {/*      <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2" onClick={getUserEmailAddress} >verify</button>*/}
                          {/*      </>*/}

                          {/*}*/}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">*/}
            {/*  <span className="carousel-control-prev-icon" aria-hidden="true" />*/}
            {/*  <span className="visually-hidden">Previous</span>*/}
            {/*</button>*/}
            {/*<button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">*/}
            {/*  <span className="carousel-control-next-icon" aria-hidden="true" />*/}
            {/*  <span className="visually-hidden">Next</span>*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="container-xxl bg-light my-5 py-0" id="viewer">
          {
            step.toString() === "2" ?
              <div className="container py-0" >
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                  <h3 className=" mb-5">Student Certificate Status</h3>
                </div>
                <div className="row g-4 justify-content-center">
                  <div className="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                      <div className="text-center p-4 pt-0">
                        <h6 className="mb-3">The certificate is valid and awarded to {certData?.FirstName} {certData?.MiddleName} {certData?.Surname} on {formatDateAndTime(certData?.AwardDate, 'date')}</h6>
                        <div className="causes-progress bg-light p-3 pt-2">
                          <div className="col-md-12">
                            <>
                              <div className=" justify-content-center table-responsive-md" style={{ width:"100%", borderStyle: 'groove', borderWidth: '3px', borderRadius: '5px' }}>
                               <table className="table table-bordered table-hover">
                                 <thead>
                                   <tr>
                                     <th>Student ID</th>
                                     <th>Student Name</th>
                                     <th>Certificate No.</th>
                                     <th>Award Date</th>
                                     <th>Degree Awarded</th>
                                     <th>Class Of Degree</th>
                                   </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                      <td>{certData?.StudentID}</td>
                                      <td>{certData?.FirstName} {certData?.MiddleName} {certData?.Surname}</td>
                                      <td>{certData?.CertificateNumber}</td>
                                      <td>{formatDateAndTime(certData?.AwardDate, 'date')}</td>
                                      <td>{certData?.Honours} {certData?.Degree}</td>
                                      <td>{certData?.ClassOfDegree}</td>
                                    </tr>
                                 </tbody>
                               </table>
                              </div>
                              <hr/>
                              <div>
                                <Document file="" onLoadSuccess={onDocumentLoadSuccess}>
                                  <Page pageNumber={pageNumber} />
                                </Document>
                                <p>
                                  Page {pageNumber} of {numPages}
                                </p>
                              </div>
                            </>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :  step.toString() === "3"  ?
                      <div className="container py-0" >
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                      <h3 className=" mb-5">Student Certificate Status</h3>
                    </div>
                    <div className="row g-4 justify-content-center">
                      <div className="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                          <div className="text-center p-4 pt-0">
                            {/*<h6 className="mb-3">The certificate verified is invalid </h6>*/}
                            <br/>
                            <div className="causes-progress bg-light p-3 pt-2">
                              <div className="col-md-12">
                                <>
                                  <div className=" justify-content-center table-responsive-md" style={{ width:"100%", borderStyle: 'groove', borderWidth: '3px', borderRadius: '5px' }}>
                                    <div className="text-center alert alert-danger mt-2"><h3>Certificate cannot be verified</h3></div>
                                  </div>
                                  <div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : <div> </div>

          }
        </div>

        {/* Footer Start */}
        <div id="footer" className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-4 col-md-6">
                <h1 className="fw-bold text-primary mb-4" style={{textAlign: 'left'}}>Baze<span className="text-white">&nbsp;University </span></h1>
                <div className="d-flex pt-2">
                  <a className="btn btn-square me-1" href="https://twitter.com/Baze_University"><i className="fab fa-twitter" /></a>
                  <a className="btn btn-square me-1" href="https://web.facebook.com/BazeUniversity/"><i className="fab fa-facebook-f" /></a>
                  <a className="btn btn-square me-1" href="https://www.instagram.com/baze_university/"><i className="fab fa-instagram" /></a>
                  <a className="btn btn-square me-0" href="https://www.linkedin.com/school/bazeuniversity/mycompany/"><i className="fab fa-linkedin-in" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6"  style={{textAlign: 'left'}}>
                <h5 className="text-light mb-4">Address</h5>
                <p><i className="fa fa-map-marker-alt me-3" />2C44+7CR, Plot 686, Jabi Airport Road Bypass, Cadastral Zone, Abuja</p>
                <p><i className="fa fa-phone-alt me-3" />+234 813 376 9658</p>
                <p><i className="fa fa-envelope me-3" />servicedesk@bazeuniversity.edu.ng</p>
              </div>
              <div className="col-lg-4 col-md-6"  style={{textAlign: 'left'}}>
                <h5 className="text-light mb-4">Quick Links</h5>
                <a className="btn btn-link" target={"_blank"} href="https://bazeuniversity.edu.ng/">Website</a>
                <a className="btn btn-link" target={"_blank"} href="https://portal.bazeuniversity.edu.ng/admission">Admissions</a>
                <a className="btn btn-link" target={"_blank"} href="https://portal.bazeuniversity.edu.ng/">Portal</a>
                <a className="btn btn-link" target={"_blank"} href="https://portal.bazeuniversity.edu.ng/help_desk">Help Desk</a>
              </div>
            </div>
          </div>
        </div>
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
      </div>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default App;
